export const APP_SIDE_MENU_CHANGE = 'APP_SIDE_MENU_CHANGE'; 
export const APP_SIDE_MENU_TOGGLE = 'APP_SIDE_MENU_TOGGLE';
export const APP_SIDE_WIDGETS_CHANGE = 'APP_SIDE_WIDGETS_CHANGE'; 
export const APP_SIDE_WIDGETS_TOGGLE = 'APP_SIDE_WIDGETS_TOGGLE';
export const APP_SET_PLAYCANVAS_IFRAME = 'APP_SET_PLAYCANVAS_IFRAME';
export const APP_SET_SELECTED_FARM = 'APP_SET_SELECTED_FARM';
export const APP_SET_SELECTED_TURBINE = 'APP_SET_SELECTED_TURBINE';
export const APP_SET_SELECTED_TURBINE_COMPONENT = 'APP_SET_SELECTED_TURBINE_COMPONENT';
export const APP_SET_PLAY_CANVAS_INITIALIZED = 'APP_SET_PLAY_CANVAS_INITIALIZED';

export const DATA_SET_DATA = 'DATA_SET_DATA';
export const DATA_SET_CURRENT_DATA_SET = 'DATA_SET_CURRENT_DATA_SET';
export const DATA_NEXT_DATA_SET = 'DATA_NEXT_DATA_SET';
export const DATA_SET_NOTIFICATIONS_COUNT = 'DATA_SET_NOTIFICATIONS_COUNT';
export const DATA_SET_REAL_WEATHER = 'DATA_SET_REAL_WEATHER';
export const DATA_SET_TIMELINE_BOUNDARIES = 'DATA_SET_TIMELINE_BOUNDARIES';
export const DATA_SET_TIMELINE_VALUE = 'DATA_SET_TIMELINE_VALUE';
export const DATA_SET_SPEED_MULTIPLIER = 'DATA_SET_SPEED_MULTIPLIER';
