import { useSelector, useDispatch } from 'react-redux';
import { getPlayCanvasIframe, getSelectedTurbineComponent } from '../../store/selectors';
import { postMessage, convertToDMS } from '../../utils';
import { iframeMsg } from '../../constants';
import * as actions from '../../store/actions';
import BackToOffshore from './BackToOffshore';
import BackToWTG from './BackToWTG';
import Status from './Status';
import Power from './Power';
import Service from './Service';
import Issues from './Issues';
import {
  Wrapper,
  ItemWrapper,
} from './style';

const TurbineInfo = ({ turbine }) => {
  const playcanvasIframe = useSelector(getPlayCanvasIframe);
  const selectedTurbineComponent = useSelector(getSelectedTurbineComponent);
  const dispatch = useDispatch();
  const warnings = turbine.properties.messages.filter((msg) => msg.type === 'warning');
  const count = warnings.length;

  const tid = turbine.properties.ID;
  const [lng, lat] = turbine.geometry.coordinates;
  const {
    EW,
    NS,
    ewd, ewm, ews,
    nsd, nsm, nss,
  } = convertToDMS(lng, lat);
  const turbineHeader = `Turbine #${tid}`;
  const turbineText = [
    <span>Position: {Math.abs(nsd)}<sup>o</sup>{Math.abs(nsm)}<sup>'</sup>{Math.abs(nss)}<sup>''</sup>{NS} {Math.abs(ewd)}<sup>o</sup>{Math.abs(ewm)}<sup>'</sup>{Math.abs(ews)}<sup>''</sup>{EW}</span>,
    <span>Status: {turbine.properties.Status === 7 ? 'Operational' : 'Inspection needed'}</span>
  ];

  let componentHeader;
  let componentText;
  if (selectedTurbineComponent === 'JACKET') {
    componentHeader = `${tid} Jacket Foundation`;
    componentText = [
    <span>Condition monitoring system – Alert 3122</span>
    ];
  }
  if (selectedTurbineComponent === 'GENERATOR') {
    componentHeader = `${tid} Nacelle`;
    componentText = [
      <span>Generator malfunction</span>
    ];
  }
  if (selectedTurbineComponent === 'BLADES') {
    componentHeader = `${tid} Blades`;
    componentText = [
      <span>Blade 01 – Leading edge erosion report – category 2</span>,
      <span>Blade 02 – Lightning detection alert – level 1</span>
    ];
  }
  
  const backToWindfarmHandler = () => {
    postMessage(playcanvasIframe, iframeMsg.UI_ACTION, iframeMsg.UI_ACTION_BACK_TO_OFFSHORE);
    dispatch(actions.app.setSelectedTurbine(null));
  };

  const backToAssetHandler = () => {
    postMessage(playcanvasIframe, iframeMsg.UI_ACTION, iframeMsg.UI_ACTION_BACK_TO_WTG);
    dispatch(actions.app.setSelectedTurbineComponent(false));
  }

  return (
    <Wrapper>
      <ItemWrapper className="back" align="center" onClick={backToWindfarmHandler}>
        <BackToOffshore />
      </ItemWrapper>
      {selectedTurbineComponent ? (
        <ItemWrapper className="back" align="center" onClick={backToAssetHandler}>
          <BackToWTG />
        </ItemWrapper>
      ) : null}
      {selectedTurbineComponent ? (
        componentHeader ? (
          <ItemWrapper flexdir="column" flexjust="center">
            <Status header={componentHeader} text={componentText}/>
          </ItemWrapper>
        ) : null
      ) : (
        <>
          <ItemWrapper flexdir="column" flexjust="center">
            <Status header={turbineHeader} text={turbineText} />
          </ItemWrapper>
          <ItemWrapper align="center">
            <Power turbine={turbine} />
          </ItemWrapper>
          <Service turbine={turbine} />
          {count ? (
            <ItemWrapper  align="center">
              <Issues count={count} />
            </ItemWrapper>
          ) : null}
        </>
      )}
    </Wrapper>
  );

}

export default TurbineInfo;
