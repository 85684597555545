import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 225px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #343144;
;
  
  padding-top: 5px;
`;

export const ItemWrapper = styled.div`
  padding:  8px 18px;
  background: #201D2F;
  backdrop-filter: blur(30px);
  display: flex;
  flex-direction: ${props => props.flexdir};
  align-items: ${props => props.align};
  justify-content: ${props => props.flexjust};
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 2px;
  min-width: 210px;
  min-height: 77px;
  margin: 2.5px 5px;

  &.back {
    min-height: 40px;
    padding:  8px 18px;
    height: 40px;
  }

  &.back:first-child {
    margin-top: 0;
  }

  & svg {
    margin-right: 11px;
  }

  &:nth-child(3) svg {
    margin-right: 8px;
  }
`;

export const Header = styled.h3`
  margin: 0;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
`;

export const Text = styled.div`
  margin: 0;
  font-size: 10px;
  line-height: 14px;
`;
