import * as types from '../types';
import { createActionCreator } from '../utils';

export const sideMenuChange = createActionCreator(types.APP_SIDE_MENU_CHANGE);

export const sideMenuToggle = createActionCreator(types.APP_SIDE_MENU_TOGGLE);

export const sideWidgetsChange = createActionCreator(types.APP_SIDE_WIDGETS_CHANGE);

export const sideWidgetsToggle = createActionCreator(types.APP_SIDE_WIDGETS_TOGGLE);

export const setPlayCanvasIframe = createActionCreator(types.APP_SET_PLAYCANVAS_IFRAME);

export const setSelectedFarm = createActionCreator(types.APP_SET_SELECTED_FARM);

export const setSelectedTurbine = createActionCreator(types.APP_SET_SELECTED_TURBINE);

export const setSelectedTurbineComponent = createActionCreator(types.APP_SET_SELECTED_TURBINE_COMPONENT);

export const setPlaycanvasInitialized = createActionCreator(types.APP_SET_PLAY_CANVAS_INITIALIZED);
