import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedFarm, getSelectedTurbine, getSelectedTurbineComponent } from '../../store/selectors';
import logo from '../../assets/images/logo.png';
import { ReactComponent as UserIcon } from '../../assets/svg/profile-icon.svg';
import {
  HeaderWrapper,
  HeaderLogo,
  Image,
  Text,
  Mode,
  InputWrapper,
  Input,
  SearchItemsList,
  ProfileWrapper,
} from './style';


const Header = props => {

  const selectedFarm = useSelector(getSelectedFarm);
  const selectedTurbine = useSelector(getSelectedTurbine);
  const selectedTurbineComponent = useSelector(getSelectedTurbineComponent);

  let modeText = 'Windfarm';
  if (selectedTurbine) {
    modeText = 'Asset WTG'
  }
  if (selectedTurbineComponent) {
    modeText = 'Component'
  }

  const inputRef = useRef();

  useEffect(() => {
    if(inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <HeaderWrapper>
      <HeaderLogo>
        <Image src={logo} />
        <Text>ODISY Portal {selectedFarm ? ` - ${selectedFarm.properties.name}`  : ''}</Text>
      </HeaderLogo>
      {selectedFarm ? (
        <Mode>{modeText}</Mode>
      ) : null}
      <ProfileWrapper>
        Admin
        <UserIcon />
      </ProfileWrapper>
    </HeaderWrapper>
  );
}

export default Header;
