import styled from 'styled-components';

export const MenuWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 0;
  height: 100%;
  z-index: 1;
  background: #323242;
  backdrop-filter: blur(15px);
  transition: all 0.3s ease-out;

  &.open {
    width: 280px;
  }

  &.dark {
    background: #292638;
  }
`;

export const ToggleWrapper =  styled.div`
  width: 20px;
  height: 20px;
  background: #201D2F;
  position: relative;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
  transition: transform .3s ease-out;
  border-radius: 2px;
  top: 5px;

  .open & {
    transform: unset;
    right: -283px
    ;
  }

  & svg {
    fill: #f0f0f5;
    margin: 0 auto;
    text-align: center;
  }
`;

export const ContentWrapper = styled.div`
  visibility: hidden;
  margin-top: -16px;
  height: 100%;

  .open & {
    visibility: unset;
  }

  & .MyAwesomeScrollbarsWrapper {
    inset: 0 !important;
  }

  & .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
    background-color: #424252 !important;
  }
`;

export const InnerWrapper = styled.div`
  padding: 0 20px;
  padding-bottom: 12px;
`;

export const Trademark = styled.p`
  height: 57px;
  padding-top: 7px;
  margin-bottom: 0;
  background-color: #323242;
  font-size: 10px;
  line-height: 15px;
  color: #878794;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;

  .dark & {
    background-color: #32324299;
  }
`;
