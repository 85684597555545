import * as types from '../../actions/types';

const initialState = {
  playCanvasIframe: undefined,
  playCanvasInitialized: false,
  sideMenu: {
    open: true,
    progress: {
      on: false,
      alarp: false,
      foundations: false,
      piles: false,
      wtg: false,
      commissioning: false,
    },
    piles: { on: true },
    boundary: { on: true },
    wtgs: { on: true },
    oss: { on: true },
    iac: { on: true },
    excables: { on: true },
    vessels: { on: false },
    labels: { on: true },
    jackets: { on: true },
    laCables: { on: true },
    eCables: { on: true },
    magnetometer: { on: true },
    power: { on: false },
    malfunctions: { on: true },
    weather: { on: true },
    datasource: { on: 'timeline' },
    showtimeline: {on: false},
    grid: { on: false },
    compas: { on: true },
    meteorological: { on: false },
    bathymetry: { on: false },
    geotechnics: { on: false },
    seabottom: {
      on: false,
      type: 'seabottom_mesh',
    },
  },
  sideWidgets: {
    open: true,
  },
  selectedFarm: null,
  selectedTurbine: null,
  selectedTurbineComponent: false,
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch(type) {
    case types.APP_SIDE_MENU_TOGGLE:
      return {
        ...state,
        sideMenu: {
          ...state.sideMenu,
          open: !state.sideMenu.open,
        }
      }
    case types.APP_SIDE_MENU_CHANGE:
      return {
        ...state,
        sideMenu: {
          ...state.sideMenu,
          [payload.key]: payload.value,
        }
      };
    case types.APP_SIDE_WIDGETS_TOGGLE:
      return {
        ...state,
        sideWidgets: {
          ...state.sideWidgets,
          open: !state.sideWidgets.open,
        }
      }
    case types.APP_SIDE_WIDGETS_CHANGE:
      return state;
    case types.APP_SET_PLAYCANVAS_IFRAME:
      return {
        ...state,
        playCanvasIframe: payload,
      }
    case types.APP_SET_SELECTED_FARM:
      return {
        ...state,
        selectedFarm: payload,
      }
    case types.APP_SET_SELECTED_TURBINE: {
      return {
        ...state,
        selectedTurbine: payload,
        selectedTurbineComponent: false,
      }
    }
    case types.APP_SET_SELECTED_TURBINE_COMPONENT:
      return {
        ...state,
        selectedTurbineComponent: payload,
      }
    case types.APP_SET_PLAY_CANVAS_INITIALIZED:
      return {
        ...state,
        playCanvasInitialized: payload,
      }
    default:
      return state;
  }
}

export default reducer;
