import { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ServiceIcon } from '../../../assets/svg/settings.svg';
import { Header, Text } from '../style';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding:  8px 18px;
  background: #16233580;
  background: #201D2F;
  backdrop-filter: blur(30px);
  min-width: 210px;
  min-height: 77px;
  margin: 2.5px 5px 0 5px;

  & svg {
    margin-right: 14px;
  }
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #16233580;
  backdrop-filter: blur(30px);
  min-width: 210px;
  margin: 0 5px 2.5px 5px;
  padding: 5px;
`;

const Button = styled.button`
  color: #FFFFFF;
  background: #162335;
  opacity: 0.3;
  backdrop-filter: blur(30px);
/* Note: backdrop-filter has minimal browser support */
  border-radius: 2px;
  border-color: #162335; 
  padding: 12px 24px;
  margin-bottom: 5px;
  font: inherit;
  line-height: 20px;
`;

const Service = ({ turbine: { properties: { Last_service, Next_service } } }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Wrapper onClick={() => setOpen(!open)}>
        <ServiceIcon />
        <InfoContainer >
          <Header>Service</Header>
          <Text>Last service {Last_service}</Text>
          <Text>Next service {Next_service}</Text>
        </InfoContainer>
      </Wrapper>
      {open ? (
        <ButtonsContainer>
          <Button onClick={() => setOpen(false)}>Order Inspection</Button>
          <Button onClick={() => setOpen(false)}>Generate PDF </Button>
        </ButtonsContainer>
      ) : null}
    </>
  );

}

export default Service;
