import styled from 'styled-components';
import { ReactComponent as Bell } from '../../assets/svg/bell.svg';

const Wrapper = styled.div`
  position: relative;
  background: #201D2F;
  font-weight: bold;
  line-height: 20px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  & svg {
    margin-right: 16px;
  }
`;

const CountWrapper = styled.div`
  color: #fff;
  background-color: #FF2E53;
  height: 12px;
  padding: 0 2px;
  border-radius: 6px;
  position: absolute;
  top: 17px;
  left: 28px;
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
`;

const Notifications = ({ count }) => {

  return (
    <Wrapper>
      <Bell />
      <CountWrapper>{count}</CountWrapper>
      Notifications
    </Wrapper>
  );

}

export default Notifications;
