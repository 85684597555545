import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 79px;
  background: #201D2F;
  border-radius: 2px;
  backdrop-filter: blur(30px);
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  /* opacity: 0.5; */
`;

export const IconValueWrapper =  styled.div`
  display: flex;
  flex-basis: 50%;
  align-items: center;
  padding-left: 18px;
`;

export const IconWrapper = styled.div`
  width: 30px;
  height: 30px;

  & svg {
    width: 100%;
    height: 100%;
  }
`;

export const ValueWrapper = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 45px;
  color: #fff;
  margin-left: 20px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* align-self: flex-end; */
  justify-self: flex-end;
  flex-basis:50%;
  padding-left: 18px;
`;

export const HeadingWrapper = styled.h3`
  font-weight: bold;
  line-height: 20px;
  margin: 0;
`;

export const TextWrapper = styled.p`
  font-size: 10px;
  line-height: 14px;
  margin: 0;
`;