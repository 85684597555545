import styled from 'styled-components';
import { ReactComponent as WarningIcon } from '../../../assets/svg/warning.svg';
import { Header, Text } from '../style';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const Issues = ({ count }) => {

  return (
    <>
    <WarningIcon />
    <InfoContainer>
      <Header>Issues</Header>
      <Text>{count < 10 ? '0' : ''}{count} Issues detected</Text>
    </InfoContainer>
  </>
  );

}

export default Issues;
