import { ReactComponent as MostlyCloudy } from '../../assets/svg/mostly-cloudy.svg';
import { ReactComponent as Cloudy } from '../../assets/svg/cloudy.svg';
import { ReactComponent as Night } from '../../assets/svg/night.svg';
import { ReactComponent as Rain } from '../../assets/svg/rain.svg';
import { ReactComponent as Sunny } from '../../assets/svg/sunny.svg';
import { ReactComponent as Thunderstorm } from '../../assets/svg/thunderstorm.svg';
import { ReactComponent as N } from '../../assets/svg/wind-N.svg';
import { ReactComponent as NE } from '../../assets/svg/wind-NE.svg';
import { ReactComponent as E } from '../../assets/svg/wind-E.svg';
import { ReactComponent as SE } from '../../assets/svg/wind-SE.svg';
import { ReactComponent as S } from '../../assets/svg/wind-S.svg';
import { ReactComponent as SW } from '../../assets/svg/wind-SW.svg';
import { ReactComponent as W } from '../../assets/svg/wind-W.svg';
import { ReactComponent as NW } from '../../assets/svg/wind-NW.svg';
import {
  Wrapper,
  HeaderContainer,
  Header,
  HeaderSmall,
  WeadherContainer,
  WeatherText,
  WeatherLine,
  WeatherGraphic,
  WeatherIcon,
} from './style';

const iconMap = {
  'Mostly Cloudly': MostlyCloudy,
  'Clear Sky': Sunny,
  'Night': Night,
  'Rainy': Rain,
  'Cloudy': Cloudy,
  'Thunderstorm': Thunderstorm,
  'Foggy': Cloudy, // TODO create icon for foggy weather
}

const windMap = {
  'default': {
    short: 'N',
    long: 'N to S',
    icon: N,
  },
  '22.5' : {
    short: 'NE',
    long: 'NE to SW',
    icon: NE,
  },
  '67.5': {
    short: 'E',
    long: 'E to W',
    icon: E,
  },
  '112.5': {
    short: 'SE',
    long: 'SE to NW',
    icon: SE,
  },
  '157.5': {
    short: 's',
    long: 'S to N',
    icon: S,
  },
  '202.5': {
    short: 'SW',
    long: 'SW to NE',
    icon: SW,
  },
  '247.5': {
    short: 'W',
    long: 'W to E',
    icon: W,
  },
  '292.5': {
    short: 'NW',
    long: 'NW to SE',
    icon: NW,
  },
  '337.5': {
    short: 'N',
    long: 'N to S',
    icon: N,
  },
}

const MeteoData = ({ weather: { description, humidity, pressure, sea_level, temp, min_temp, max_temp, visibility, waves_hight, wind : { speed, deg } } }) => {
  const Icon = iconMap[description];

  const windDir = Object.keys(windMap).reduce((acc, key) => {
    if (deg > parseFloat(key)) return windMap[key];
    return acc;
  }, windMap.default);

  return (
    <Wrapper>
      <HeaderContainer>
        <Header>MeteoData</Header>
        <HeaderSmall>{description}</HeaderSmall>
      </HeaderContainer>
      <WeadherContainer>
        <WeatherText>
          <WeatherLine>
            <p>Temperature</p><p>{Math.floor(temp)}<sup>o</sup>C</p>
          </WeatherLine>
          <WeatherLine>
            <p>Humidity</p><p>{humidity}%</p>
          </WeatherLine>
          <WeatherLine>
            <p>Pressure</p><p>{pressure} mbar</p>
          </WeatherLine>
        </WeatherText>
        <WeatherGraphic>
          {Icon ? (
            <WeatherIcon><Icon /></WeatherIcon>
          ) : null}
          {/* <WeatherLine><p>{description}</p></WeatherLine> */}
          <WeatherLine><p>{Math.floor(max_temp)} / {Math.floor(min_temp)} <sup>o</sup>C</p></WeatherLine>
        </WeatherGraphic>
      </WeadherContainer>
      <WeadherContainer>
        <WeatherText>
          <WeatherLine>Wind conditions</WeatherLine>
          <WeatherLine>Directon: From {windDir.long}</WeatherLine>
          <WeatherLine>Speed {speed}m/s</WeatherLine>
          <WeatherLine><span>Angle {deg}<sup>o</sup></span></WeatherLine>
        </WeatherText>
        <WeatherGraphic>
          <windDir.icon />
        </WeatherGraphic>
      </WeadherContainer>
    </Wrapper>
  );
}

export default MeteoData;
