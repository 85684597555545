import { useMemo, useRef } from 'react';
import Slider, { Range, createSliderWithTooltip } from 'rc-slider';
import { SliderWrapper } from './style';

const MySlider = ({ tooltip, onChange, ...props}) => {
  const timeoutId = useRef();
  const changeHandler = (value) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    timeoutId.current = setTimeout(() => {
      onChange(value);
    }, 0);
  };

  let SliderCmp = useMemo(() => {
    return createSliderWithTooltip(Slider);
  }, []);
  if (!tooltip) {
    SliderCmp = Slider;
  }

  return (
    <SliderWrapper>
      <SliderCmp 
        {...props}
        onChange={onChange}
      />
    </SliderWrapper>
  );
};

const MyRange = ({tooltip, onChange, ...props}) => {
  const timeoutId = useRef();
  const changeHandler = (value) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    timeoutId.current = setTimeout(() => {
      onChange(value);
    }, 0);
  };

  let RangeCmp = useMemo(() => {
    return createSliderWithTooltip(Range);
  }, []);
  if (!tooltip) {
    RangeCmp = Range;
  }

  return (
    <SliderWrapper>
      <RangeCmp 
        {...props}
        onChange={onChange}
      />
    </SliderWrapper>
  );
};

export {
  MySlider as Slider,
  MyRange as Range
}
