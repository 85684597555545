
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSideMenuState, getPlayCanvasIframe } from '../../store/selectors';
import * as actions from '../../store/actions';
import { iframeMsg } from '../../constants';
import { postMessage } from '../../utils';
import { ReactComponent as Plus } from '../../assets/svg/plus.svg';
import { ReactComponent as Minus } from '../../assets/svg/minus.svg';
import { ReactComponent as TwoD } from '../../assets/svg/2d.svg';
import { ReactComponent as TreeD } from '../../assets/svg/3d.svg';
import { ReactComponent as Above } from '../../assets/svg/above.svg';
import { ReactComponent as Below } from '../../assets/svg/below.svg';
import { ReactComponent as Marker } from '../../assets/svg/marker.svg';
import { ReactComponent as Grid } from '../../assets/svg/grid.svg';
import { ReactComponent as Compass } from '../../assets/svg/compass.svg';
import { ReactComponent as Vessel } from '../../assets/svg/vessel-map.svg';
import { ReactComponent as ROV } from '../../assets/svg/rov.svg';
import { ReactComponent as Trancher } from '../../assets/svg/trancher.svg';
import {
  MenuWrapper,
  ItemWrapper,
} from './style';

const MapMenu = props => {
  const sideMenuState = useSelector(getSideMenuState);
  const iframe = useSelector(getPlayCanvasIframe);
  const [perspectiv3D, setPerspectiv3D] = useState(true);
  const [aboveWater, setAboveWater] = useState(true);
  const showGrid = sideMenuState.grid.on;
  const dispatch = useDispatch();

  const toggleGrid = () => {
    const key = 'grid';

    dispatch(actions.app.sideMenuChange({
      key,
      value: {
        ...sideMenuState[key],
        ...{ on: !showGrid },
      }
    }));
  };

  const perspectiveHandler = () => {
    if(perspectiv3D) {
      postMessage(iframe, iframeMsg.UI_ACTION, iframeMsg.UI_ACTION_PERSPECTIVE_2D);
      setPerspectiv3D(false);
    } else {
      postMessage(iframe, iframeMsg.UI_ACTION, iframeMsg.UI_ACTION_PERSPECTIVE_3D);
      setPerspectiv3D(true);
    }
  };

  const aboveBelowHandler = () => {
    if (aboveWater) {
      postMessage(iframe, iframeMsg.UI_ACTION, iframeMsg.GO_BELOW_WATER);
      setAboveWater(false);
    } else {
      postMessage(iframe, iframeMsg.UI_ACTION, iframeMsg.GO_ABOVE_WATER);
      setAboveWater(true);
    }
  }

  const d2 = { icon: TwoD, id: 'twod', action: perspectiveHandler };
  const d3 = { icon: TreeD, id: 'treed', action: perspectiveHandler };
  const aboveIcon = { icon: Above, id: 'treed', action: aboveBelowHandler };
  const belowIcon = { icon: Below, id: 'treed', action: aboveBelowHandler };

  const items = [
    { icon: Plus, id: 'plus', action: () => postMessage(iframe, iframeMsg.UI_ACTION, iframeMsg.UI_ACTION_ZOOM_IN) },
    { icon: Minus, id: 'minus', action: () => postMessage(iframe, iframeMsg.UI_ACTION, iframeMsg.UI_ACTION_ZOOM_OUT) },
    perspectiv3D ? d2 : d3,
    aboveWater ? belowIcon : aboveIcon,
    { icon: Marker, id: 'marker', action: () => postMessage(iframe, iframeMsg.UI_ACTION_GO_TO_INITIAL_LOCATION) },
    { icon: Grid, id: 'grid', action: toggleGrid },
    { icon: Vessel, id: 'vessel', action: () => postMessage(iframe, iframeMsg.UI_ACTION, iframeMsg.UI_ACTION_SHOW_ANIMATION, 'vessel') },
    { icon: ROV, id: 'row', action: () => postMessage(iframe, iframeMsg.UI_ACTION, iframeMsg.UI_ACTION_SHOW_ANIMATION, 'row') },
    { icon: Trancher, id: 'trancher', action: () => postMessage(iframe, iframeMsg.UI_ACTION, iframeMsg.UI_ACTION_SHOW_ANIMATION, 'trancher') },
  ];

  return (
    <MenuWrapper expanded={false}>
      {items.map((item, index) => (
        <ItemWrapper key={index} onClick={item.action}>
          <item.icon className={showGrid && item.id === 'grid' ? 'grid-active' : ''} />
        </ItemWrapper>
      ))}
    </MenuWrapper>
  );
}

export default MapMenu;
