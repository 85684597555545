import styled from 'styled-components';
import { Range } from '../../../shared/Slider';

const Container = styled.div`
  margin: 3px 0;
`;

const BarsRange = ({ min, max, play, timepoints, current = 0, phases, count, value, onChange }) => {
  const breakpoints = Object.keys(phases).reduce((acc, phase) => {
    acc[phase] = phases[phase] + acc.total;
    acc.total += phases[phase];
    return acc;
  }, { total: 0 });
  current += breakpoints.development;
  value = value.map((val) => val + breakpoints.development);
  const nowIndicator = play ? current : value[0];

  const Rects = [];
  for(let i = 0; i < count; i++) {
    let gradient = 'url(#inactive)';
    if ((i > current * count / breakpoints.total) || (i < (value[0]) * count / breakpoints.total)) {
      gradient = 'url(#inactive)';
    } else {
      if (i < breakpoints.decommissioning * count / breakpoints.total) gradient = 'url(#decommissioning)';
      if (i < breakpoints['o&m'] * count / breakpoints.total) gradient = 'url(#om)';
      if (i < breakpoints.commissioning * count / breakpoints.total) gradient = 'url(#commissioning)';
      if (i < breakpoints.construction * count / breakpoints.total) gradient = 'url(#construction)';
      if (i < breakpoints.development * count / breakpoints.total) gradient = 'url(#development)';
    }
    if (i === Math.floor(nowIndicator * count / breakpoints.total) + 1) {
      gradient = 'url(#now)'
      Rects.push(<rect key={i} width="2" height="48" x={`${i*4}`} fill="#FFFFFFFF" />)
    } else {
      Rects.push(<rect key={i} width="2" height="32" x={`${i*4}`} y="2" fill={gradient} />)
    }
  }

  return (
    <Container>
      <svg width="1060" height="38">
        <defs>
          <linearGradient id="inactive" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="#ffffff80"/>
            <stop offset="100%" stopColor="#ffffff00"/>
          </linearGradient>
          <linearGradient id="development" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="#8596A2"/>
            <stop offset="100%" stopColor="#8596A200"/>
          </linearGradient>
          <linearGradient id="construction" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="#926CFF"/>
            <stop offset="100%" stopColor="#926CFF00"/>
          </linearGradient>
          <linearGradient id="commissioning" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="#3F97E9"/>
            <stop offset="100%" stopColor="#3F97E900"/>
          </linearGradient>
          <linearGradient id="om" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="#71D057"/>
            <stop offset="100%" stopColor="#71D05700"/>
          </linearGradient>
          <linearGradient id="decommissioning" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="#FF685F"/>
            <stop offset="100%" stopColor="#FF685F00"/>
          </linearGradient>
          <linearGradient id="now" x1="0" x2="0" y1="0" y2="1">
            <stop offset="0%" stopColor="#FFFFFFFF"/>
            <stop offset="100%" stopColor="#FFFFFFFF"/>
          </linearGradient>
        </defs>
        <g>
          {Rects}
        </g>
      </svg>
      <Range
        value={value}
        min={min}
        max={breakpoints.total - 1}
        disabled={play}
        onChange={(val) => {
          onChange([Math.max(val[0] - breakpoints.development, 0), Math.min(val[1] - breakpoints.development, timepoints.length - 1)]) 
        }} />
    </Container>
  );

}

export default BarsRange;
