import { useSelector } from 'react-redux';
import { DateTime, Duration } from 'luxon';
import SimpleInfo from '../SimpleInfo';
import { DateLabel } from '../shared';
import Graph from '../shared/Graph';
import { getCurrentDataSet } from '../../store/selectors'
import {
  Wrapper,
  ContentWrapper,
  GraphWrapper,
  HourLabelsWrapper,
  HourLabel,
} from './style';

const PowerGraph = () => {
  const { power_24: farmPowerData = [] } = useSelector(getCurrentDataSet);
  const aggregate = farmPowerData.reduce((acc, val) => {
    return acc += val;
  }, 0);

  const today = DateTime.local().startOf('hour');
  const oneDay = Duration.fromObject({ days: 1 });
  const oneHour = Duration.fromObject({ hour: 1 });

  let time = today.minus(oneDay);
  const last24Hours = [];
  const timeForLabels = [];
  for (let ii = 0; ii < farmPowerData.length - 1; ii++) {
    if (ii !== 0) {
      time = time.plus(oneHour);
    }
    if((ii % 4) === 0) {
      timeForLabels.push(time);
    }
    last24Hours.push([time.toISO(), farmPowerData[ii]]);
  }

  if (farmPowerData.length === 0) return null;

  return (
    <Wrapper>
      <SimpleInfo icon="power" value={aggregate} title="MWh" text="Power Generated" />
      <ContentWrapper>
        <DateLabel data={last24Hours.map((val) => val[0])} />
        <GraphWrapper>
          <Graph data={last24Hours} />
          <HourLabelsWrapper>
            {timeForLabels.map((time) => (
              <HourLabel key={time.toFormat('HH')}>{time.toFormat('HH')}h</HourLabel>
            ))}
            <HourLabel>{timeForLabels[0].toFormat('HH')}h</HourLabel>
          </HourLabelsWrapper>
        </GraphWrapper>
      </ContentWrapper>
    </Wrapper>
  );

}

export default PowerGraph;
