import styled from 'styled-components';
import { convertToDMS } from '../../utils';

export const Wrapper = styled.div`
  font-size: 10px;
  line-height: 14px;
  text-align: left;
  color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & p {
    margin: 0;
  }
`;

const Location = ({ name, lng, lat, }) => {
  const {
    EW,
    NS,
    ewd, ewm, ews,
    nsd, nsm, nss,
  } = convertToDMS(lng, lat);

  return (
    <Wrapper>
      <p>Location: {name}</p>
      <p>Coordinates: {Math.abs(nsd)}<sup>o</sup>{Math.abs(nsm)}<sup>'</sup>{Math.abs(nss)}<sup>''</sup>{NS} {Math.abs(ewd)}<sup>o</sup>{Math.abs(ewm)}<sup>'</sup>{Math.abs(ews)}<sup>''</sup>{EW}</p>
    </Wrapper>
  );
}

export default Location;
