import { useRef, useEffect } from 'react';

const Graph = ({
  data,
  gradients = ["#ff04", "#0000"],
  stroke = "#ffff00"
}) => {
  const canvasRef = useRef();


  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const norm = normalize(data, canvas.width, canvas.height);
    norm.unshift([0, canvas.height]);
    norm.push([canvas.width, canvas.height]);

    // Create gradient
    const grd = ctx.createLinearGradient(0, 0, 0, 200);
    gradients.forEach((color, index) => {
      grd.addColorStop(index/(gradients.length - 1), color);
    });

    ctx.strokeStyle=stroke;

    const clipPath = new Path2D();
    norm.forEach(point => {
      clipPath.lineTo(...point);
    });
    clipPath.closePath();

    const line = new Path2D();
    line.moveTo(...norm[1])
    norm.slice(1,-1).forEach(point => {
      line.lineTo(...point);
    });

    ctx.stroke(line);
    ctx.save();
    ctx.clip(clipPath);
    // Fill with gradient
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, 300, 200);

    return () => {
      ctx.restore();
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  }, [data, gradients, stroke]);

  return (
    <canvas ref={canvasRef} widht="300" height="200" style={{width: "100%", height: '100%' }} />
  );
};

const normalize = (data, width, height) => {
  const start = Date.parse(data.slice(0, 1)[0][0]);
  const end = Date.parse(data.slice(-1)[0][0]);
  const timeRange = end - start;
  const max = Math.max(...data.map((val) => val[1]));
  return data.map(([time, val]) => {
    return [
      ((Date.parse(time) - start)/timeRange) * width,
      height - ((val / max) * height),
    ];
  });
}

export default Graph;
