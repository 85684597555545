export const postMessage = (iframe, type, data, object) => {
  const { contentWindow } = iframe;
  contentWindow.postMessage({
    type,
    data,
    object,
  }, '*');
};

const toDMS = (number) => {
  const d = Math.floor(number);
  const restm = (number - d) * 60;
  const m = Math.floor(restm);
  const rests = restm - m;
  const s = (rests * 60).toFixed(1);
  return [d, m, s];
};

export const convertToDMS = (lng, lat) => {
  const EW = lng > 0 ? 'E' : 'W';
  const NS = lat > 0 ? 'N' : 'S';
  const [ewd, ewm, ews] = toDMS(lng);
  const [nsd, nsm, nss] = toDMS(lat);

  return {
    EW,
    NS,
    ewd, ewm, ews,
    nsd, nsm, nss,
  }
};

const addZero = (str) => {
  if (str.toString().length === 1) return `0${str}`;
  return str;
};

export const getDateString = (timeString, shortYear = false, addZeros = true) => {
  const date = new Date(timeString);
  let datePart = date.getDate();
  let montPart = date.getMonth() + 1;
  let yearPart = date.getFullYear().toString();
  if (addZeros) {
    datePart = addZero(datePart);
    montPart = addZero(montPart);
  }
  if (shortYear) {
    yearPart = yearPart.substr(-2);
  }
  return `${montPart}/${datePart}/${yearPart}`;
};

export const getTimeString = (timeString, addZeros = true) => {
  const date = new Date(timeString);
  let hoursPart = date.getHours();
  let minutesPart = date.getMinutes();
  if (addZeros) {
    hoursPart = addZero(hoursPart);
    minutesPart = addZero(minutesPart);
  }
  return `${hoursPart}:${minutesPart}`;
};

export const rgba2Hex = (color) => {
  return `#${color.map((col) => {
    if (col === 0) return '00';
    return col.toString(16);
  }).join('')}`
}
