export const getSideMenuState = (state) => state.app.sideMenu;
export const getSideMenuOpen = (state) => state.app.sideMenu.open;
export const getSideWidgetsState = (state) => state.app.sideWidgets;
export const getSideWidgetsOpen = (state) => state.app.sideWidgets.open;
export const getPlayCanvasIframe = (state) => state.app.playCanvasIframe;
export const getSelectedFarm = (state) => state.app.selectedFarm;
export const getSelectedTurbine = (state) => state.app.selectedTurbine;
export const getSelectedTurbineComponent = (state) => state.app.selectedTurbineComponent;
export const isPlaycanvasInitialized = (state) => state.app.playCanvasInitialized;

export const getData = (state) => state.data.data;
export const getCurrentDataSet = (state) => state.data.currentDataSet;
export const getCurrentPhase = (state) => state.data.currentDataSet.phase;
export const getCurrentWeather = (state) => state.data.currentDataSet.weather;
export const getCurrentTime = (state) => state.data.currentTime;
export const getWindfarms = (state) => state.data.windfarms;
export const getSpeedFactor = (state) => state.data.speedFactor * state.data.speedMultiplier;
export const getSpeedMultiplier = (state) => state.data.speedMultiplier;
export const getTimepoints = (state) => state.data.timepoints;
export const getTurbinesWithNotifications = (state) => state.data.currentDataSet.turbines.filter((turb) => turb.properties.statusChange);
export const getGeneralMessages = (state) => state.data.currentDataSet.messages;
export const getVessels = (state) => state.data.vessels;
export const getOperatingTurbines = (state) => state.data.currentDataSet.turbines.filter((turb) => turb.properties.operational);
export const getTimelineValue = (state) => state.data.timelineValue;
export const getTimelineBoundaries = (state) => state.data.timelineBoundaries;
export const getTurbineMessages = (state) => state.data.currentDataSet.turbines.reduce((acc, turb) => acc.concat(turb.properties.messages.map((msg) => ({...msg, turbineId: turb.properties.ID}))), []);
