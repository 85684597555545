import { CheckBoxRounded } from '../shared';
import { ReactComponent as Chevron } from '../../assets/svg/chevron.svg';
import {
  HeaderWrapper,
  ChevronWrapper,
  Text,
} from './style';

const ControlsHeader = ({ title, open, withCheckbox, checked, onChange,  }) => {

  return (
    <HeaderWrapper>
      <Text>{title}</Text>
      {withCheckbox ? (
        <CheckBoxRounded checked={checked} onChange={(_, checked) => onChange(checked)}/>
      ) : (
        <ChevronWrapper open={open}><Chevron /></ChevronWrapper>
      )}
    </HeaderWrapper>
  );
}

export default ControlsHeader;
