import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const LocationWrapper = styled.div`
  position: absolute;
  left: 292px;
  height: 100%;
  display: flex;
  align-items: center;
`;