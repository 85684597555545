import Header from '../ControlsHeader';
import {
  Wrapper,
  Content,
} from './style';

const ControlsContainer = ({ name, children, open, withCheckbox, onClick, onChange }) => {
  return (
    <Wrapper onClick={onClick}>
      <Header
        title={name}
        checked={open}
        onChange={onChange}
        withCheckbox={withCheckbox}
        open={open}
      />
      {open && children ? (
        <Content className="open">
          {children}
        </Content>
      ) : null}
    </Wrapper>
  );
}

export default ControlsContainer;
