import styled from 'styled-components';


export const MenuWrapper = styled.div`
  position: absolute;
  top: 5px;
  left: ${(props) => props.expanded ? '-242px' : '-40px'};
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ItemWrapper = styled.div`
  width: 35px;
  height: 35px;
  margin-bottom: 3px;
  cursor: pointer;

  & svg.grid-active rect:first-child {
    fill-opacity: 1;
  }
`;
