import { CheckBoxRounded } from '../shared';
import {
  Wrapper,
  Header,
  Content,
} from './style';

const ControlSwitch = ({ name, on, setOn, showHeader = true, showContent = false, subitem = false }) => {

  return (
    <Wrapper>
      {showHeader 
        ? (
            <Header className={subitem ? 'sub' : ''} onClick={(evt) => evt.stopPropagation()}>
              {name}
              <CheckBoxRounded
                checked={on}
                onChange={(_, checked) => setOn(checked)} />
            </Header>
        ) : null
      }

      {on && showContent
        ? (
            <Content
              className={on ? 'open' : ''}
            >
              {/* eventual content will go here */}
            </Content>
        )
        : null
      }
    </Wrapper>
  );
}

export default ControlSwitch;
