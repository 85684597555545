import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as actions from '../../store/actions';

const PlayCanvas = ({ src, style }) => {
  const iframeRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    iframeRef.current.focus();
    dispatch(actions.app.setPlayCanvasIframe(iframeRef.current));
  }, [dispatch]);

  return (
    <iframe
      title="Wind Farm"
      id="playcanvas"
      src={src}
      style={style}
      ref={iframeRef}
    ></iframe>
  );

}

export default PlayCanvas;
