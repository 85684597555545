import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Scrollbar } from 'react-scrollbars-custom';
import { postMessage } from '../../utils';
import { iframeMsg } from '../../constants';
import { getGeneralMessages, getTurbineMessages, getPlayCanvasIframe } from '../../store/selectors';
import { ReactComponent as Warning } from '../../assets/svg/warning-small.svg';
import { ReactComponent as Critical } from '../../assets/svg/critical-small.svg';

const iconMap = {
  warning: Warning,
  critical: Critical,
};

const Wrapper = styled.div`
  font-size: 10px;
  line-height: 14px;
  padding: 3px;
  background: #201D2F;
  backdrop-filter: blur(30px);
/* Note: backdrop-filter has minimal browser support */
  border-radius: 2px;
  margin-bottom: 4px;
`;

const HeaderWrapper = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const NumWrapper = styled.div`
  display: inline-block;
  margin-left: 16px;
`;

const HeaderText = styled.div`
  display: inline-block;
  margin-left: 16px;
`;

const ContentWrapper = styled.div`
  height: ${({count}) => count < 4 ? `${count * 48.5}px` : '145.5px'};
`;

const MessageWrapper = styled.div`
  background: #343144;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  padding: 0 19px;
  border-radius: 2px;

  & p {
    margin-left: 17px;
    width: 80%
  }
`;

const Alerts = () => {
  const [open, setOpen] = useState(true);
  const iframe = useSelector(getPlayCanvasIframe);
  const gMessages = useSelector(getGeneralMessages);
  const tMessages = useSelector(getTurbineMessages);
  const count = gMessages.length + tMessages.length;
  const [noScrollY, setNoScrollY] = useState(true);

  const mouseEnter = () => {
    setNoScrollY(false);
  }
  const mouseLeave = () => {
    setNoScrollY(true);
  }

  const tMesageClickHandler = (id) => {
    postMessage(iframe, iframeMsg.UI_ACTION, iframeMsg.UI_ACTION_FOCUS_ASSET, id);
  }

  return (
    <Wrapper>
      <HeaderWrapper onClick={() => setOpen(!open)}>
        <NumWrapper>{`${count < 10 ? '0' : ''}${count}`}</NumWrapper>
        <HeaderText>Emergency Alerts</HeaderText>
      </HeaderWrapper>
      {open ? (
        <ContentWrapper
          count={count}
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
        >
          <Scrollbar
            style={{ width: '100%', height: '100%' }}
            noScrollX
            noScrollY={(count < 4) || noScrollY}
            wrapperProps={{
              renderer: props => {
                const { elementRef, ...restProps } = props;
                return <div {...restProps} ref={elementRef} className="MyAwesomeScrollbarsWrapper" />;
              }
            }}
          >
            {tMessages.map(({ type, turbineId, message}) => {
              const Icon = iconMap[type];
              return (
                <MessageWrapper 
                  key={`${turbineId} ${message}`}
                  onClick={() => tMesageClickHandler(turbineId)}
                >
                  <Icon />
                  <p>Turbine ID: {turbineId} {message}</p>
                </MessageWrapper>
              )
            })}
            {gMessages.map(({ type, message}) => {
              const Icon = iconMap[type];
              return (
                <MessageWrapper key={message}>
                  <Icon />
                  <p>{message}</p>
                </MessageWrapper>
              )
            })}
          </Scrollbar>
        </ContentWrapper>
      ) : null}
    </Wrapper>
  );

}

export default Alerts;
