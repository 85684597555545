import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    color: #f0f0f5;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px; 
  }

  input {
    border: none;
    color: inherit;
    font: inherit;
    
    &:focus {
      outline: none
    }
  }
`;