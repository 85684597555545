export const UI_ACTION = 'UI_ACTION';
export const UI_ACTION_ZOOM_IN = 'ZOOM_IN';
export const UI_ACTION_ZOOM_OUT = 'ZOOM_OUT';
export const UI_ACTION_PERSPECTIVE_2D = 'PERSPECTIVE_2D';
export const UI_ACTION_PERSPECTIVE_3D = 'PERSPECTIVE_3D';
export const UI_ACTION_SHOW_ANIMATION = 'SHOW_ANIMATION';
export const UI_ACTION_GO_TO_INITIAL_LOCATION = 'GO_TO_INITIAL_LOCATION';
export const UI_ACTION_FOCUS_ASSET = 'FOCUS_ASSET';
export const UI_ACTION_BACK_TO_OFFSHORE = 'BACK_TO_OFFSHORE';
export const UI_ACTION_BACK_TO_WTG = 'BACK_TO_WTG';
export const GO_ABOVE_WATER = 'GO_ABOVE_WATER';
export const GO_BELOW_WATER = 'GO_BELOW_WATER';

export const DATA_SET_CHANGE = 'DATA_SET_CHANGE';
export const APP_SIDE_MENU_CHANGE = 'APP_SIDE_MENU_CHANGE';