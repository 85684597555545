import { ReactComponent as BackIcon } from '../../../assets/svg/previous.svg';
import { Header } from '../style';

const Back = props => {

  return (
    <>
      <BackIcon style={{ transform: 'scale(0.54)' }} />
      <Header>Back to Windfarm</Header>
    </>
  );

}

export default Back;
