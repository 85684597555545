import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { app, data } from './reducers'

const initialState = {};
const reducer = combineReducers({
  app,
  data,
});

// const sagaMiddleware = createSagaMiddleware();
const middlewares = [];
const enhancers = [applyMiddleware(...middlewares)];

let composeEnhacers = compose;
if (
  process.env.NODE_ENV === 'development' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) {
  composeEnhacers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}

const store = createStore(reducer, initialState, composeEnhacers(...enhancers));

export default store;
