import styled from 'styled-components';
import { Container } from '../SimpleInfo/style';

export const Wrapper = styled.div`
  width: 100%;
  height: 231px;
  background: #201D2F;
  /* opacity: 0.5; */
  backdrop-filter: blur(30px);
  border-radius: 2px;
  margin-bottom: 4px;

  & ${Container} {
    background-color: transparent;
    backdrop-filter: unset;
  }
`;

export const ContentWrapper = styled.div`
  padding: 0 20px;
`;

export const GraphWrapper = styled.div`
  width: 100%;
  height: 65px;
  margin-top: 10px;
`;

export const HourLabelsWrapper = styled.div`
  color: #fff8;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 8px;
  line-height: 11px;
`;

export const HourLabel = styled.p`
  width: 11px;
  margin: 0;
`;
