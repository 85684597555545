import styled from 'styled-components';

export const Wrapper = styled.div`
  &:first-child {
    padding-top: 12px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 17px;

  &.sub {
    padding-left: 20px;
  }
`;

export const Content = styled.div`
  opacity: 0;
  display: flex;
  flex-direction: column;
  transition: all .3s ease-out;
  
  &.open {
    opacity: 1;
  }
`;
