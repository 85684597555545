import styled from 'styled-components';
import { ReactComponent as PoverIcon } from '../../../assets/svg/power.svg';
import { Header, Text } from '../style';

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Power = ({ turbine: { properties: { Power_24h, Power_7days } } }) => {

  return (
    <>
      <PoverIcon />
      <InfoContainer>
        <Header>Power generated</Header>
        <Text>Last 24h {Power_24h}MWh</Text>
        <Text>Last 7dh {Power_7days}MWh</Text>
      </InfoContainer>
    </>
  );

}

export default Power;
