import styled from 'styled-components';

export const Wrapper = styled.div`
    display: ${(props) => props.visible ? 'block' : 'none'};
    background-color: #16233580;
    border-radius: 2px;
    backdrop-filter: blur(3px);
    padding: 0 18px 12px 18px;
    margin-bottom: 32px;
    position: absolute;
    height: 210px;
    width: 980px;
    left: 50%;
    transform: scale(.8) translateX(-62%);
    bottom: 30px;

    @media (min-width: 1441px) {
      transform: translate(-50%, 0);
    }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderText = styled.h3`
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  margin-top: 15px;
  margin-bottom: 5px;
`;

export const PhaseText = styled.p`
  margin: 0;
  font-size: 10px;
  line-height: 14px;
  text-transform: capitalize;
  margin-bottom: 15px;
`;

export const HeaderControlls = styled.div`
  width: 74px;
  display: flex;
  justify-content: space-between;

  & svg {
    cursor: pointer;
  }

  & svg.active path {
    stroke: #2d8db1;
    fill: #2d8db1;
  }
`;

export const Content = styled.div``
