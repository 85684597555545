import {
  Container,
  IconValueWrapper,
  InfoWrapper,
  ValueWrapper,
  IconWrapper,
  HeadingWrapper,
  TextWrapper,
} from './style';

import { ReactComponent as Warning } from '../../assets/svg/warning.svg';
import { ReactComponent as Turbine } from '../../assets/svg/turbine.svg';
import { ReactComponent as Vessel } from '../../assets/svg/vessel.svg';
import { ReactComponent as Power } from '../../assets/svg/power.svg';
import { ReactComponent as Danilo } from '../../assets/svg/danilo.svg';

const iconMap = {
  warning: Warning,
  turbine: Turbine,
  vessel: Vessel,
  power: Power,
  danilo: Danilo,
}

const SimpleInfo = ({icon, value, title, text }) => {
  const Icon = iconMap[icon];

  return (
    <Container>
      <IconValueWrapper>
        <IconWrapper><Icon /></IconWrapper>
        <ValueWrapper>{value}</ValueWrapper>
      </IconValueWrapper>
      <InfoWrapper>
        <HeadingWrapper>{title}</HeadingWrapper>
        <TextWrapper>{text}</TextWrapper>
      </InfoWrapper>
    </Container>
  );
}

export default SimpleInfo;
