import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 10px;
  line-height: 14px;
  padding: 16px 20px;
  background: #201D2F;
  backdrop-filter: blur(30px);
/* Note: backdrop-filter has minimal browser support */
  border-radius: 2px;
  margin-bottom: 4px;
`;

export const HeaderContainer = styled.div`
  margin-bottom: 8px;
`;

export const Header = styled.h3`
  margin: 0;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 8px;
`;

export const HeaderSmall = styled.p`
  margin: 0;
  font-size: 15px;
  text-transform: capitalize;
`;

export const WeadherContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;

  &:nth-child(2) {
    margin-bottom: 12px;
  }

  &:nth-child(3) svg {
    margin-bottom: 12px;
  }
`;

export const WeatherText = styled.div`
  flex-basis: 65%;
`;

export const WeatherLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
   & p {
     margin: 0;
   }
`;

export const WeatherGraphic = styled.div`
  margin-top: -5px;
  flex-basis: 35%;
  padding: 0 0 0 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WeatherIcon = styled.div``;
