import styled from 'styled-components';

export const Button = styled.button`
  height: 30px;
  background: #424252;
  border: 1px solid #424252;
  border-radius: 3px;
  line-height: 24px;
  color: inherit;
  font: inherit;
  cursor: pointer;

  &:hover {
    border: 1px solid #f0f0f5;
  }

  &:focus {
    outline: none;
  }
`;