import { useSelector } from 'react-redux';
import DeckGL from '@deck.gl/react';
import { GeoJsonLayer } from '@deck.gl/layers';
import * as turf from '@turf/turf';
import styled from 'styled-components';
import { rgba2Hex } from '../../utils';
import { getCurrentDataSet, getWindfarms, getCurrentPhase } from '../../store/selectors';

const colors = [
  [255, 255, 255, 255],
  [104, 183, 255, 255],
  [0, 255, 209, 255],
  [97, 255, 0, 255],
  [250, 255, 0, 255],
  [0, 255, 0, 255],
  [255, 165, 0, 255],
  [255, 0, 0, 255],
  // [255, 153, 0, 255],
  // [255, 77, 0, 255],
  // [255, 90, 189, 255],
];

const legendItems = [
  'ALARP',
  'Piles',
  'Foundations',
  'WTG',
  'Commissioning',
];

const Title = styled.p`
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  margin: 0;
  background: #201D2F;
  padding-left: 21px;
  padding-top: 12px;
`;

const ItemWrapper = styled.div`
  position: relative;
  background: #201D2F;
  height: ${(props) => `${props.height}px`};
`;

const LegendWrapper = styled.div`
  padding: 20px;
`;

const LegendItem = styled.div`
  font-size: 10px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #F0F0F5;
`;

const ColorWrapper = styled.div`
  width: 25px;
  height: 15px;
  background-color: ${(props) => props.color};
`;

const LegendText = styled.p`
  margin: 0 0 0 18px;
`;

const SmallMap = () => {
  const selectedFarm = useSelector(getWindfarms)[0];
  const { turbines } = useSelector(getCurrentDataSet);
  const phase = useSelector(getCurrentPhase);
  const center = turf.centerOfMass(selectedFarm);

  const INITIAL_VIEW_STATE = {
    longitude: center.geometry.coordinates[0],
    latitude: center.geometry.coordinates[1],
    zoom: 9,
    maxZoom: 22,
    bearing: 0,
    pitch: 0,
    transitionDuration: 300,
  };

  const geojsonLayer = new GeoJsonLayer({
    id: 'farm-turbines',
    data: turbines,
    visible: true,
    stroked: false,
    filled: true,
    getFillColor: d => {
      if (phase === 'commissioning') return colors[4];
      switch(d.properties.Status) {
        case 0:
        case 1:
        case 2:
          return colors[0];
        case 3: 
          return colors[2];
        case 4:
          return colors[1];
        case 5:
        case 6:
          return colors[3];
        case 7:
          return colors[5];
        case 8:
          return colors[6];
        case 9:
          return colors[7];
        default:
          return colors[5];
      }
    },
    getRadius: 4,
    pointRadiusUnits: 'pixels',
    autoHighlight: false,
  });

  const layers = [geojsonLayer];

  return (
    <>
    <Title>Construction Progress</Title>
    <ItemWrapper height={260}>
      <DeckGL
        initialViewState={INITIAL_VIEW_STATE}
        controller={false}
        layers={layers}
        getCursor={() => "default"}
      />
    </ItemWrapper>
    <ItemWrapper height={199}>
      <Legend />
    </ItemWrapper>
    </>
  );
}

export const Legend = () => {
  const items = legendItems.map((legend, index) => {
    return (
      <LegendItem>
        <ColorWrapper color={rgba2Hex(colors[index])} />
        <LegendText>{legend}</LegendText>
      </LegendItem>
    );
  })

  return (
    <LegendWrapper>
      {items}
    </LegendWrapper>
  );
}

export default SmallMap;
