import React from 'react';
import Header from '../components/header';
import Footer from '../components/Footer';
import SideMenu from '../components/SideMenu';
import SideWidgets from '../components/SideWidgets';
import MapMenu from '../components/MapMenu';
import {
  AppWrapper,
  HeaderWrapper,
  SideMenuWrapper,
  MainWrapper,
  FooterWrapper,
} from './style';

const Main = ({ showInterface, children }) => {
  return (
    <>
      <AppWrapper>
        <HeaderWrapper><Header /></HeaderWrapper>
        <MainWrapper>
          { showInterface ?
            (
              <>
                <SideMenuWrapper><SideMenu /></SideMenuWrapper>
                <SideMenuWrapper>
                  <SideWidgets>
                    <MapMenu />
                  </SideWidgets>
                </SideMenuWrapper>
              </>
            ) : null
          }
          {children}
        </MainWrapper>
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      </AppWrapper>
    </>
  );
};

export default Main;
