import {
  CheckBoxWrapper1,
  Checkbox1,
  Input1,
  CheckBoxWrapper2,
  Checkbox2,
  Input2,
} from './styles';

export const CheckBox = ({ label, name, value, defaultChecked, onChange }) => (
  <CheckBoxWrapper1>
    {label}
    <Input1
      type="checkbox"
      name={name}
      value={value}
      defaultChecked={defaultChecked}
      // eslint-disable-next-line no-shadow
      onChange={({ target: { value, checked } }) => onChange(value, checked)}
    />
    <Checkbox1 />
  </CheckBoxWrapper1>
);

export const CheckBoxRounded = ({ label, name, value, checked, defaultChecked, onChange }) => (
  <CheckBoxWrapper2>
    {label}
    <Input2
      type="checkbox"
      name={name}
      value={value}
      defaultChecked={defaultChecked}
      checked={checked}
      // eslint-disable-next-line no-shadow
      onChange={({ target: { value, checked } }) => onChange(value, checked)}
    />
    <Checkbox2 />
  </CheckBoxWrapper2>
);
