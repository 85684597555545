import { Header, Text } from '../style';

const Status = ({ header, text }) => {

  return (
    <>
      <Header>{header}</Header>
      {text.map((txt) => <Text>{txt}</Text>)}
    </>
  );

}

export default Status;
