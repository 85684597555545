import { getDateString, getTimeString } from '../../../utils';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  font-size: 10px;
  line-height: 18px;
  color: #fff8;
  & p {
    margin: 0;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 10px;
`;


export const DateLabel = ({ data, showHours = false, children }) => {
  const startDate = data.slice(0, 1)[0];
  const endDate = data.slice(-1)[0];


  

  return (
    <Wrapper>
      {children}
      <LabelWrapper>
        <p>{`${getDateString(startDate, true, true)}${showHours ? `-${getTimeString(startDate, true)}` : ''}`}</p>
        <p>{`${getDateString(endDate, true, true)}${showHours ? `-${getTimeString(endDate, true)}` : ''}`}</p>
      </LabelWrapper>
    </Wrapper>
  );

}

export default DateLabel;
