import styled from 'styled-components';

export const AppWrapper = styled.div`
  display: grid;
  min-height: 100vh;
  overflow: hidden;
  background-color: #323242;
  grid-template-columns: auto;
  grid-template-rows: 50px 1fr;
  grid-template-areas: " header "
                       " main "
                       " footer ";
`;

export const HeaderWrapper = styled.div`
  grid-area: header;
`;

export const SideMenuWrapper = styled.div`
`;

export const MainWrapper = styled.div`
  grid-area: main;
  position: relative;
  display: flex;
`;

export const FooterWrapper = styled.div`
  /* grid-area: footer; */
  position: fixed;
  bottom: 0;
  text-align: center;
  width: calc(100vw - 280px - 292px - 100px);
  /* background-color: #282d3e; */
  font-size: 10px;
  padding-left: ${(props) => props.sideMenuOpen ? '280px' : 0};
  line-height: 10px;
  color: #878794;
  height: 50px;

  @media(max-width: 700px) {
    max-width: 100vw;
    padding-left: 0;
  }
`;
