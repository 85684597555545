import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getCurrentTime } from '../../store/selectors';
import { getDateString, getTimeString } from '../../utils';

export const Wrapper = styled.div`
  margin-left: 96px;
  font-size: 10px;
  line-height: 14px;
  text-align: left;
  color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & p {
    margin: 0;
  }

  & p:first-child {
    margin-bottom: 3px;
  }
`;

const DateTime = props => {
  const currentTimeString = useSelector(getCurrentTime);
  let time = getTimeString(currentTimeString, true);
  let date = getDateString(currentTimeString, false, true);

  return (
    <Wrapper>
      <p>Time: {time}</p>
      <p>Date: {date}</p>
    </Wrapper>
  );

}

export default DateTime;
