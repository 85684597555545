import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Content = styled.div`
  padding: 0 20px;
  opacity: 0;
  transition: all .3s ease-out;
  background: #201D2F80;

  &.open {
    opacity: 1;
    padding-bottom: 12px;
  }
`;
