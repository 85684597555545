import * as types from '../types';
import { createActionCreator } from '../utils';

export const setData = createActionCreator(types.DATA_SET_DATA);

export const setCurrentDataSet = createActionCreator(types.DATA_SET_CURRENT_DATA_SET);

export const getNextDataSet = createActionCreator(types.DATA_NEXT_DATA_SET);

export const setNotificationsCount = createActionCreator(types.DATA_SET_NOTIFICATIONS_COUNT);

export const setRealWeather = createActionCreator(types.DATA_SET_REAL_WEATHER);

export const setTimelineBoundaries = createActionCreator(types.DATA_SET_TIMELINE_BOUNDARIES);

export const setTimelineValue = createActionCreator(types.DATA_SET_TIMELINE_VALUE);

export const setSpeedMultiplier = createActionCreator(types.DATA_SET_SPEED_MULTIPLIER);
