import styled from 'styled-components';

export const CheckBoxWrapper1 = styled.label`
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 30px;
  cursor: pointer;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #222b45;
  user-select: none;
`;

export const Input1 = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const Checkbox1 = styled.span`
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #8f9bb3;
  border-radius: 4px;

  ${CheckBoxWrapper1}:hover ${Input1} ~ & {
    border-color: #263238;
  }

  ${CheckBoxWrapper1} ${Input1}:checked ~ & {
    background-color: #fff;
  }

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 5px;
    top: 0;
    width: 7px;
    height: 13px;
    border: solid #263238;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  ${CheckBoxWrapper1} ${Input1}:checked ~ &:after {
    display: block;
  }
`;

export const CheckBoxWrapper2 = styled.label`
  display: block;
  position: relative;
  padding-left: 36px;
  margin-bottom: 30px;
  cursor: pointer;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #222b45;
  user-select: none;
`;

export const Input2 = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const Checkbox2 = styled.span`
  position: absolute;
  top: 6px;
  left: 0;
  height: 16px;
  width: 32px;
  border-radius: 8px;
  background-color: #100E1B;
;

  ${CheckBoxWrapper2}:hover ${Input2} ~ && {
    background-color: #2F9BFF;
;
  }

  ${CheckBoxWrapper2} ${Input2}:checked ~ & {
    background-color: #2F9BFF;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    left: 2px;
    top: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    transition: transform .3s ease-out;
  }

  ${CheckBoxWrapper2} ${Input2}:checked ~ &:after {
    transform: translateX(16px);
  }
`;
