import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Scrollbar } from 'react-scrollbars-custom';
import SideMenuControls from '../SideMenuControls';
import * as actions from '../../store/actions';
import { getSideMenuOpen } from '../../store/selectors';
import { ReactComponent as RightArrow} from '../../assets/svg/arrow.svg';
import {
  MenuWrapper,
  ToggleWrapper,
  ContentWrapper,
  InnerWrapper,
  Trademark,
} from './style';

const SideMenu = () => {
  const theme = 'dark';
  const open = useSelector(getSideMenuOpen);
  const dispatch = useDispatch();

  const toggle = () => {
    dispatch(actions.app.sideMenuToggle());
  }

  return (
    <MenuWrapper className={`${open ? 'open' : ''} ${theme === 'dark' ? 'dark' : ''}`}>
      <ToggleWrapper onClick={toggle}>
        <RightArrow />
      </ToggleWrapper>
      <ContentWrapper>
        <Scrollbar
          style={{ width: '100%', height: 'calc(100%)' }}
          wrapperProps={{
            renderer: props => {
              const { elementRef, ...restProps } = props;
              return <div {...restProps} ref={elementRef} className="MyAwesomeScrollbarsWrapper" />;
            }
          }}
        >
          <InnerWrapper>
            <SideMenuControls />
          </InnerWrapper>
        </Scrollbar>
      </ContentWrapper>
    </MenuWrapper>
  );

}

export default SideMenu;
