import { useSelector } from 'react-redux';
import * as turf from '@turf/turf';
import Location from '../Location';
import DateTime from '../DateTime';
import { getSelectedFarm } from '../../store/selectors';
import {
  Wrapper,
  LocationWrapper,
} from './style';

const Footer = props => {
  const selectedFarm = useSelector(getSelectedFarm);
  let name = "";
  let [lng, lat] = [0, 0];
  if (selectedFarm) {
    name = selectedFarm.properties.name;
    [lng, lat] = turf.centerOfMass(selectedFarm).geometry.coordinates;
  }

  return (
    <Wrapper>
      <LocationWrapper>
        { selectedFarm 
          ? (
              <>
                <Location
                  name={name}
                  lng={lng}
                  lat={lat}
                />
                <DateTime />
              </>
          ) : null
        }
      </LocationWrapper>
    </Wrapper>
  );
}

export default Footer;
