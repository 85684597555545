import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DeckGL from './components/deckGL';
import { DateTime, Duration } from 'luxon'; 
import PlayCanvas from './components/PlayCanvas';
import Play from './components/shared/Play';
import Layout from './layouts';
import { getCurrentDataSet, getPlayCanvasIframe, getSelectedFarm, getSelectedTurbine, getSideMenuState, getTimepoints, isPlaycanvasInitialized } from './store/selectors';
import * as actions from './store/actions';
import { postMessage } from './utils';
import { iframeMsg } from './constants';
import allData from './data/data.json';

function App() {
  const selectedFarm = useSelector(getSelectedFarm);
  const selectedTurbine = useSelector(getSelectedTurbine);
  const currentDataSet = useSelector(getCurrentDataSet);
  const playCanvasIframe = useSelector(getPlayCanvasIframe);
  const playCanvasInitialized = useSelector(isPlaycanvasInitialized);
  const sideMenuState = useSelector(getSideMenuState);
  const timepoints = useSelector(getTimepoints);
  const dispatch = useDispatch();
  const showTimeline = sideMenuState.datasource.on === 'timeline' && sideMenuState.showtimeline.on;
  const useRealdata = sideMenuState.datasource.on === 'realdata';

  const farmClickHandler = (farm) => dispatch(actions.app.setSelectedFarm(farm));

  const generateTimepoints = (data, interval, start, speedFactor) => {
    const duration = Duration.fromObject({seconds: interval});
    let time = start;
    if (interval < 0) data.reverse();
    const transformedTimepointsArray = data
      .map((timepoint, index) => {
        if (index !== 0) {
          time = time.plus(duration);
        }
        return {
          ...timepoint,
          time: time.toISO({ includeOffset: false }),
        } 
      });
      if (interval < 0) transformedTimepointsArray.reverse();
      const timepointsObject = transformedTimepointsArray.reduce((acc, point) => {
        acc[point.time] = point;
        acc[point.time]['speedFactor'] = speedFactor;
        return acc;
      }, {});
      return timepointsObject;
  }

  useEffect(() => {
    if (useRealdata) {
      const requestOptions = {
        method: 'GET',
      };
      fetch("https://api.openweathermap.org/data/2.5/weather?lat=41.05643859750237&lon=-70.48886558851758&units=metric&appid=9ec0cd26465d01bba48f379a273f0074", requestOptions)
      .then(response => response.json())
      .then(result => {
        const { weather: [{ description }], main: { temp, temp_max: max_temp, temp_min: min_temp, pressure, humidity }, wind } = result;
        dispatch(actions.data.setRealWeather({
          description,
          humidity,
          pressure,
          temp,
          min_temp,
          max_temp,
          wind,
        }))
      })
      .catch(error => console.log('error', error));
    }
  }, [dispatch, useRealdata]);
  
  useEffect(() => {
    const { windfarms, data } = allData;
    let now = DateTime.now();
    const realtimeStartIndex = data.findIndex((timepoint) => timepoint.id === 1000);
    const pastData = generateTimepoints(data.slice(0, realtimeStartIndex + 1), -12*60*60, now, 20);
    const realData = generateTimepoints(data.slice(realtimeStartIndex), 30, now, 2);
    const realtimeStart = Object.keys(realData)[0];
    dispatch(actions.data.setData({ windfarms, realtimeStart, ...pastData, ...realData}));
    document.addEventListener('contextmenu', (evt) => {
      evt.preventDefault();
      return false;
    }, true);
  }, [dispatch]);

  useEffect(() => {

    const listener = ({ data: { type, data } }) => {
      switch (type) {
        case 'TURBINE_SELECTED':
          dispatch(actions.app.setSelectedTurbine(currentDataSet.turbines.filter((feature) => feature.properties.ID === data)[0]));
          break;
        case 'TURBINE_ELEMENT_SELECTED':
          dispatch(actions.app.setSelectedTurbineComponent(data));
          break;
        case 'PLAY_CANVAS_INITIALIZED':
          dispatch(actions.app.setPlaycanvasInitialized(true));
          break;
        default:
          break;
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    }
  }, [dispatch, currentDataSet]);

  useEffect(() => {
    if (playCanvasIframe && currentDataSet && playCanvasInitialized) {
      postMessage(playCanvasIframe, iframeMsg.DATA_SET_CHANGE, currentDataSet);
    }
  }, [playCanvasIframe, playCanvasInitialized, currentDataSet]);

  return (
      <Layout showInterface={!!selectedFarm}>
        {
          selectedFarm ? 
          (
            <>
              <PlayCanvas
                style={{
                  flexGrow: 1,
                  width: '100%',
                  border: 'none',
                }}
                src="play.html"
              />
              <Play
                title="Timeline"
                visible={showTimeline && !selectedTurbine && timepoints.length}
                animationChange={(value) => dispatch(actions.data.setTimelineValue(value))}
                rangeChange={(value) => dispatch(actions.data.setTimelineBoundaries(value))}
                />
            </>
          ) :
          (
            <DeckGL
              onClick={farmClickHandler}
            />
          ) 
        }
      </Layout>
  );
}

export default App;
