
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ControlsContainer from '../ControlsContainer';
import ControlSwitch from '../ControlSwitch';
import * as actions from '../../store/actions';
import { getSideMenuState, getPlayCanvasIframe } from '../../store/selectors';
import { postMessage } from '../../utils';
import { iframeMsg } from '../../constants';
import {
  Wrapper,
  Content,
} from './style';

const SideMenuControls = props => {
  const sideMenuState = useSelector(getSideMenuState);
  const playCanvasIframe = useSelector(getPlayCanvasIframe);
  const [open, setOpen] = useState({
    windfarm: false,
    below: false,
    status: false,
    navigation: false,
    environment: false,
    data: false,
  });
  const dispatch = useDispatch();

  const openHanddler = (val) => {
    setOpen({
      ...open,
      ...val,
    });
  };

  const menuChangeHandler = (key, val) => {
    dispatch(actions.app.sideMenuChange({
      key,
      value: {
        ...sideMenuState[key],
        ...val,
      }
    }));
  };

  useEffect(() => {
    if(playCanvasIframe) {
      postMessage(playCanvasIframe, iframeMsg.APP_SIDE_MENU_CHANGE, sideMenuState);
    }
  }, [sideMenuState, playCanvasIframe]);

  return (
    <Wrapper>
      <Content>
        <ControlsContainer
          name="Timeline"
          withCheckbox={true}
          open={sideMenuState.datasource.on === 'timeline'}
          onChange={(val) => menuChangeHandler('datasource', { on: val ? 'timeline' : 'realdata' })}
        >
          {sideMenuState.datasource.on === 'timeline' ? (
            <ControlSwitch
              name="Show timeline"
              subitem={true}
              on={sideMenuState.showtimeline.on}
              setOn={(val) => menuChangeHandler('showtimeline', { on: val })}
            />
          ) : null}
        </ControlsContainer>
        <ControlsContainer
          name="Construction Progress"
          open={sideMenuState.progress.on}
          onChange={(val) => menuChangeHandler('progress', { on: val })}
          withCheckbox={true}
        >
          <ControlSwitch
            name="ALARP"
            on={sideMenuState.progress.alarp}
            setOn={(val) => menuChangeHandler('progress', { alarp: val })}
          />
          <ControlSwitch
            name="Fonudations"
            on={sideMenuState.progress.foundations}
            setOn={(val) => menuChangeHandler('progress', { foundations: val })}
          />
          <ControlSwitch
            name="Piles"
            on={sideMenuState.progress.piles}
            setOn={(val) => menuChangeHandler('progress', { piles: val })}
          />
          <ControlSwitch
            name="WTG"
            on={sideMenuState.progress.wtg}
            setOn={(val) => menuChangeHandler('progress', { wtg: val })}
          />
          <ControlSwitch
            name="Commissioning"
            on={sideMenuState.progress.commissioning}
            setOn={(val) => menuChangeHandler('progress', { commissioning: val })}
          />
        </ControlsContainer>
        <ControlsContainer
          name="Environment"
          open={!sideMenuState.progress.on && open.environment}
          onClick={() => openHanddler({environment: !open.environment})}
        >
          <ControlSwitch
            name="Meteorological"
            on={sideMenuState.meteorological.on}
            setOn={(val) => menuChangeHandler('meteorological', { on: val })}
          />
          <ControlSwitch
            name="Bathymetry"
            on={sideMenuState.bathymetry.on}
            setOn={(val) => menuChangeHandler('bathymetry', { on: val })}
          />
          <ControlSwitch
            name="Geotechnics"
            on={sideMenuState.geotechnics.on}
            setOn={(val) => menuChangeHandler('geotechnics', { on: val })}
          />
        </ControlsContainer>
        <ControlsContainer
          name="Windfarm"
          open={!sideMenuState.progress.on && open.windfarm}
          onClick={() => openHanddler({windfarm: !open.windfarm})}
        >
          <ControlSwitch
            name="Consent Boundary"
            on={sideMenuState.boundary.on}
            setOn={(val) => menuChangeHandler('boundary', { on: val })}
          />
          <ControlSwitch
            name="WTGs"
            on={sideMenuState.wtgs.on}
            setOn={(val) => menuChangeHandler('wtgs', { on: val })}
          />
          <ControlSwitch
            name="OSS"
            on={sideMenuState.oss.on}
            setOn={(val) => menuChangeHandler('oss', { on: val })}
          />
          <ControlSwitch
            name="IAC"
            on={sideMenuState.iac.on}
            setOn={(val) => menuChangeHandler('iac', { on: val })}
          />
          <ControlSwitch
            name="Export Cables"
            on={sideMenuState.excables.on}
            setOn={(val) => menuChangeHandler('excables', { on: val })}
          />
          <ControlSwitch
            name="Vessels"
            on={sideMenuState.vessels.on}
            setOn={(val) => menuChangeHandler('vessels', { on: val })}
          />
        </ControlsContainer>
        <ControlsContainer
          name="Status"
          open={!sideMenuState.progress.on && open.status}
          onClick={() => openHanddler({status: !open.status})}
        >
          <ControlSwitch
            name="Power"
            on={sideMenuState.power.on}
            setOn={(val) => menuChangeHandler('power', { on: val })}
          />
          <ControlSwitch
            name="Malfunctions"
            on={sideMenuState.malfunctions.on}
            setOn={(val) => menuChangeHandler('malfunctions', { on: val })}
          />
          <ControlSwitch
            name="Weather"
            on={sideMenuState.weather.on}
            setOn={(val) => menuChangeHandler('weather', { on: val })}
          />
        </ControlsContainer>
        <ControlsContainer
          name="Navigation"
          open={!sideMenuState.progress.on && open.navigation}
          onClick={() => openHanddler({navigation: !open.navigation})}
        >
          <ControlSwitch
            name="Compas"
            on={sideMenuState.compas.on}
            setOn={(val) => menuChangeHandler('compas', { on: val })}
          />
        </ControlsContainer>
      </Content>
    </Wrapper>
  );
}

export default SideMenuControls;
