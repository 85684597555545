import DeckGL from '@deck.gl/react';
import { useSelector } from 'react-redux';
import { GeoJsonLayer, IconLayer, TextLayer } from '@deck.gl/layers';
import { StaticMap } from 'react-map-gl';
import * as turf from '@turf/turf';
import { getWindfarms } from '../../store/selectors';
import windfarmIcon from '../../assets/images/wind-farm.png';

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const MAP_STYLE_DARK = 'mapbox://styles/babicm992/cki8q1d7p4uw919rxuuug4kee';
const MAP_STYLE_LITE = 'mapbox://styles/babicm992/ckirix6r300tf1aql7uzv0zaz';
const MAP_STYLE_SATELITE = 'mapbox://styles/mapbox/satellite-v9';
const MAP_STYLE_LITE_FREE = 'https://basemaps.cartocdn.com/gl/positron-nolabels-gl-style/style.json';

const INITIAL_VIEW_STATE = {
  longitude: 0,
  latitude: 0,
  zoom: 8,
  maxZoom: 22,
  minZoom: 4,
  bearing: 0,
  pitch: 0,
  transitionDuration: 300,
};

const ICON_MAPPING = {
  farm: {x: 0, y: 0, width: 185, height: 139, mask: true}
};

const Deck = ({ onClick }) => {
  const windFarms = useSelector(getWindfarms);
  if (windFarms && windFarms.length) {
    const center = turf.centerOfMass(turf.featureCollection(windFarms));
    INITIAL_VIEW_STATE.longitude = center.geometry.coordinates[0];
    INITIAL_VIEW_STATE.latitude = center.geometry.coordinates[1];
  }

  const geojsonLayer = new GeoJsonLayer({
    id: 'farms',
    data: windFarms,
    visible: true,
    pickable: true,
    stroked: true,
    filled: true,
    extruded: false,
    lineWidthUnits: 'pixels',
    lineWidthMinPixels: 1,
    getLineColor: [255, 255, 255, 64],
    getFillColor: [255, 255, 255, 8],
    autoHighlight: true,
    highlightColor: [255, 255, 255, 16],
  });

  const iconLayer = new IconLayer({
    id: 'icon-layer',
    data: windFarms,
    pickable: false,
    // iconAtlas and iconMapping are required
    // getIcon: return a string
    iconAtlas: windfarmIcon,
    iconMapping: ICON_MAPPING,
    getIcon: d => 'farm',
    sizeScale: 10,
    sizeUnits: 'meters',
    getPosition: d => turf.centerOfMass(d).geometry.coordinates,
    getSize: 1000,
    getColor: [255, 255, 255, 255],
  });

  // console.log(turf.bboxPolygon(turf.bbox(windFarms[0])))

  const textLayer = new TextLayer({
    id: 'text-layer',
    data: windFarms,
    pickable: false,
    getPosition: d => {
      const bb = turf.bboxPolygon(turf.bbox(d));
      return turf.midpoint(bb.geometry.coordinates[0][2], bb.geometry.coordinates[0][3]).geometry.coordinates;
    },
    getText: d => d.properties.name,
    sizeScale: 1,
    sizeUnits: 'pixels',
    getSize: 40,
    getAngle: 0,
    getColor: [255, 255, 255, 255],
    getTextAnchor: 'middle',
    getAlignmentBaseline: 'center',
    getPixelOffset: [0, -40],
  });

  const layers = [geojsonLayer, iconLayer, textLayer];

  return (
    <DeckGL
      // ref={deckRef}
      initialViewState={INITIAL_VIEW_STATE}
      controller={true}
      layers={layers}
      onClick={(info) => onClick(info.object)}
    >
      <StaticMap
        // ref={mapRef}
        mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
        preserveDrawingBuffer={true}
        mapStyle={MAP_STYLE_SATELITE}
      />
    </DeckGL>
  );
}

export default Deck;
