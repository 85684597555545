import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Scrollbar } from 'react-scrollbars-custom';
import Notifications from '../Notifications';
import Alerts from '../Alerts';
import MeteoData from '../MeteoData/';
import PowerGraph from '../PowerGraph';
import SimpleInfo from '../SimpleInfo';
import TurbineInfo from '../TurbineInfo';
import SmallMap from '../SmallMap';
import * as actions from '../../store/actions';
import { getSideWidgetsOpen, getCurrentWeather, getSelectedTurbine, getSideMenuState, getTurbinesWithNotifications, getVessels, getOperatingTurbines, getTurbineMessages, getCurrentPhase } from '../../store/selectors';
import { ReactComponent as RightArrow} from '../../assets/svg/arrow.svg';
import {
  MenuWrapper,
  ToggleWrapper,
  WidgetsWrapper,
  ContentWrapper,
  InnerWrapper,
  AllWrapper,
} from './style';

const SideWidgets = ({ children }) => {
  const theme = 'dark';
  const open = useSelector(getSideWidgetsOpen);
  const sideMenuState = useSelector(getSideMenuState);
  const weather = useSelector(getCurrentWeather);
  const selectedTurbine = useSelector(getSelectedTurbine);
  const notificationsCount = useSelector(getTurbinesWithNotifications).length;
  const turbinesCount = useSelector(getOperatingTurbines).length;
  const turbineMessages = useSelector(getTurbineMessages);
  const vessels = useSelector(getVessels);
  const phase = useSelector(getCurrentPhase);
  const dispatch = useDispatch();
  const progressOpen = sideMenuState.progress.on;

  const toggle = () => {
    dispatch(actions.app.sideWidgetsToggle());
  }

  let cls = '';
  if (open) cls = 'open';
  if (selectedTurbine) cls = 'open wide';

  return (
    <MenuWrapper className={`${cls} ${theme === 'dark' ? 'dark' : ''}`}>
      <ToggleWrapper onClick={toggle}>
        <RightArrow />
      </ToggleWrapper>
      {children}
      <ContentWrapper>
        <Scrollbar
          style={{ width: '100%', height: '100%' }}
          wrapperProps={{
            renderer: props => {
              const { elementRef, ...restProps } = props;
              return <div {...restProps} ref={elementRef} className="MyAwesomeScrollbarsWrapper" />;
            }
          }}
        >
          <AllWrapper>
          { selectedTurbine ? <TurbineInfo turbine={selectedTurbine} /> : null }
            <InnerWrapper>
              <WidgetsWrapper>
                <Notifications count={notificationsCount} />
                <Alerts />
                {sideMenuState.weather.on && weather ? (
                  <MeteoData 
                    weather={weather}
                  />
                ): null}
                {sideMenuState.wtgs.on ? (
                  <SimpleInfo
                    icon="turbine"
                    value={turbinesCount}
                    title="Turbines"
                    text="Operating"
                  />
                ) : null}
                {sideMenuState.malfunctions.on ? (
                  <SimpleInfo
                    icon="warning"
                    value={turbineMessages.filter((msg) => msg.type === 'warning' || msg.type === 'critical').length}
                    title="Turbines"
                    text="Malfunctional"
                  />
                ) : null}
                {sideMenuState.vessels.on ? (
                  <SimpleInfo
                    icon="vessel"
                    value={vessels}
                    title="Vessels"
                    text="Detected"
                  />
                ) : null}
                {phase === 'o&m' ? (
                  <SimpleInfo
                    icon="danilo"
                    value="06"
                    title="Personnel"
                    text="At the site"
                  />
                ) : null}
                {sideMenuState.power.on ? (
                  <PowerGraph />
                ) : null}
                {progressOpen ? (
                  <SmallMap />
                ) : null
                }
              </WidgetsWrapper>
            </InnerWrapper>
          </AllWrapper>
        </Scrollbar>
      </ContentWrapper>
    </MenuWrapper>
  );
}

export default SideWidgets;
