import * as types from '../../actions/types';

const initialState = {
  index: 0,
  windfarms: [],
  data: undefined,
  timepoints: undefined,
  timelineValue: undefined,
  timelineBoundaries: undefined,
  currentTime: undefined,
  realtimeStart: undefined,
  currentDataSet: undefined,
  speedFactor: undefined,
  speedMultiplier: 1,
  notificationsCount: 0,
  vessels: 0,
  messages: {
    turbines: [],
    general: [],
  },
}

const reducer = (state = initialState, { type, payload }) => {
  let timepoint;
  let data;
  let generalMessages, vesselsIncrement;
  switch(type) {
    case types.DATA_SET_DATA:
      const windfarms = payload.windfarms;
      delete payload.windfarms;
      const realtimeStart = payload.realtimeStart;
      delete payload.realtimeStart;
      const timepoints = Object.keys(payload);
      payload = copyData(payload);
      const startTime = timepoints[0];
      return {
        ...state,
        windfarms,
        data: payload,
        timepoints,
        timelineBoundaries: [0, timepoints.length - 1],
        currentTime: startTime,
        realtimeStart,
        currentDataSet: payload[startTime],
        speedFactor: payload[startTime].speedFactor,
      }
      case types.DATA_SET_TIMELINE_BOUNDARIES:
        timepoint = state.timepoints[payload[0]];
        data = state.data[timepoint];

        generalMessages = data.messages ?? [];
        vesselsIncrement = data.vessels ? data.vessels.length : 0;

        return {
          ...state,
          timelineBoundaries: payload,
          currentTime: timepoint,
          currentDataSet: data,
          speedFactor: data.speedFactor,
          index: state.timepoints.indexOf(timepoint),
          vessels: state.vessels + vesselsIncrement,
          messages: {
            ...state.messages,
            ...generalMessages,
          },
        }
      case types.DATA_SET_TIMELINE_VALUE:
        if (payload === undefined) return { ...state, timelineValue: undefined };
        timepoint = state.timepoints[payload];
        data = state.data[timepoint];
        generalMessages = data.messages ?? [];
        vesselsIncrement = data.vessels ? data.vessels.length : 0;

        return {
          ...state,
          currentTime: timepoint,
          currentDataSet: data,
          timelineValue: payload,
          speedFactor: data.speedFactor,
          index: state.timepoints.indexOf(timepoint),
          vessels: state.vessels + vesselsIncrement,
          messages: {
            ...state.messages,
            ...generalMessages,
          },
        }

      case types.DATA_SET_CURRENT_DATA_SET:
        if (payload) {
          const data = state.data[payload];

          const generalMessages = data.messages ?? [];
          const vesselsIncrement = data.vessels ? data.vessels.length : 0;

          return {
            ...state,
            currentTime: payload,
            currentDataSet: data,
            speedFactor: data.speedFactor,
            index: state.timepoints.indexOf(payload),
            vessels: state.vessels + vesselsIncrement,
            messages: {
              ...state.messages,
              ...generalMessages,
            },
        };
      } else {
        return state;
      }
    case types.DATA_SET_REAL_WEATHER:
      return {
        ...state,
        currentDataSet: {
          ...state.currentDataSet,
          weather: payload,
        }
      }
    case types.DATA_NEXT_DATA_SET:
      const nextIndex = state.index + 1;
      const nextTimepoint = state.timepoints[nextIndex]
      return {
        ...state,
        currentDataSet: state.data[nextTimepoint],
        currentTime: nextTimepoint,
        index: nextIndex,
      }
    case types.DATA_SET_NOTIFICATIONS_COUNT:
      return {
        ...state,
        notificationsCount: payload,
      }
    case types.DATA_SET_SPEED_MULTIPLIER:
      return {
        ...state,
        speedMultiplier: payload,
      }
    default:
      return state;
  }
}

const isTurbineOperational = (turbine) => {
  if (turbine.properties.Status !== 7) return false;
  return true;
}

const copyData = (data) => {
  const result = Object.values(data).reduce((acc, timepoint) => {
    const newTimepoint = {...timepoint};
    const previousTimepoint = acc.previousTimepoint;
    newTimepoint.turbines = timepoint.turbines.map((turb, index) => {
      const newTurbine = {...turb};
      newTurbine.properties['statusChange'] = false;
      newTurbine.properties['operational'] = false;
      if (previousTimepoint && turb.properties.Status !== previousTimepoint.turbines[index].properties.Status) {
        newTurbine.properties['statusChange'] = true;
      }
      newTurbine.properties.messages = turb.properties.messages || [];
      if (previousTimepoint) {
        newTurbine.properties.messages = newTurbine.properties.messages.concat(previousTimepoint.turbines[index].properties.messages);
      }
      if (timepoint.id >= 1000) {
        newTurbine.properties['operational'] = isTurbineOperational(newTurbine);
      }
      if (timepoint.id > 1000) {
        newTurbine.properties.Last_service =  previousTimepoint.turbines[index].properties.Last_service;
        newTurbine.properties.Next_service =  previousTimepoint.turbines[index].properties.Next_service;
        newTurbine.properties.Power_24h =  previousTimepoint.turbines[index].properties.Power_24h;
        newTurbine.properties.Power_7days =  previousTimepoint.turbines[index].properties.Power_7days;
      }
      return newTurbine;
    });
    newTimepoint['messages'] = timepoint.messages || [];
    if (previousTimepoint) {
      newTimepoint['messages'] = newTimepoint['messages'].concat(previousTimepoint.messages);
    }

    acc[timepoint.time] = newTimepoint;
    acc.previousTimepoint = newTimepoint;
    return acc;
  }, {});
  delete result['previousTimepoint'];
  return result;
}

export default reducer;
