import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 0 20px;
  background: #1A172A;
;
  border-radius: 3px;
  margin-top: 5px;
  position: relative;
`;

export const ChevronWrapper = styled.div`
  position: absolute;
  right: 18px;
  top: 12px;
  margin-right: 14px;
  transform: ${(props) => props.open ? 'rotate(-90deg)' : 'rotate(0)'};
  transition: all 0.3s ease-out;
`;

export const Text = styled.h3`
    color: #878794;
    font-size: 15px;
    font-weight: 700;
    color: #f0f0f5;
`;

