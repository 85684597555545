import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;
const Phase = styled.div`
  text-transform: capitalize;
  border-bottom: 3px solid #71D057;
  border-radius: 3px;
  font-size: 10px;
  line-height: 13px;
;
  &.development {
    border-bottom: 3px solid #8596A2;
  }
  &.construction {
    border-bottom: 3px solid #926CFF;
  }
  &.commissioning {
    border-bottom: 3px solid #3F97E9;
  }
  &.decommissioning {
    border-bottom: 3px solid #FF685F;
  }
`;

const Phases = ({ phases }) => {
  const total = phases.total;
  delete phases.total;
  const phaseDivs = Object.keys(phases).map((phase) => (
    <Phase key={phase} className={phase} style={{ width: `${(phases[phase] / total) * 100}%`}}>{phase}</Phase>
  ));

  return (
    <Wrapper>
      {phaseDivs}
    </Wrapper>
  );

}

export default Phases;
