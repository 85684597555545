import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BarsRange from './BarsRange';
import Phases from './Phases';
import { DateLabel } from '../'
import { ReactComponent as PlayIcon } from '../../../assets/svg/play.svg';
import { ReactComponent as PauseIcon } from '../../../assets/svg/pause.svg';
import { ReactComponent as StopIcon } from '../../../assets/svg/stop.svg';
import { ReactComponent as LoopIcon } from '../../../assets/svg/loop.svg';
import { ReactComponent as X1Icon } from '../../../assets/svg/x1.svg';
import { ReactComponent as X2Icon } from '../../../assets/svg/x2.svg';
import { ReactComponent as X3Icon } from '../../../assets/svg/x3.svg';
import { getSpeedFactor, getSpeedMultiplier, getData, getCurrentPhase, getTimepoints, getTimelineValue, getTimelineBoundaries, getSelectedTurbine } from '../../../store/selectors';
import * as actions from '../../../store/actions';
import {
  Wrapper,
  Header,
  HeaderText,
  PhaseText,
  HeaderControlls,
  Content,
} from './style';

const Play = ({ title, visible, animationChange, rangeChange }) => {
  const timepoints = useSelector(getTimepoints);
  const value = useSelector(getTimelineBoundaries);
  const timelineValue = useSelector(getTimelineValue);
  const step = useRef(timelineValue);
  const [play, setPlay] = useState(false);
  const [pause, setPause] = useState(false);
  const [repeat, setRepeat] = useState(false);
  const data = useSelector(getData);
  const speedFactor = useSelector(getSpeedFactor);
  const speedMultiplier = useSelector(getSpeedMultiplier);
  const selectedTurbine = useSelector(getSelectedTurbine);
  const phase = useSelector(getCurrentPhase);
  const speedFactorRef = useRef();
  const repeatRef = useRef(false);
  const pauseRef = useRef(false);
  const startTime = useRef();
  const animation = useRef();
  const dispatch = useDispatch();
  speedFactorRef.current = speedFactor;
  pauseRef.current = selectedTurbine || pause;
  // pause = !!selectedTurbine;
  const phases = Object.values(data).reduce((acc, point) => {
    if (!acc[point.phase]) acc[point.phase] = 0;
    acc[point.phase] += 1;
    acc.total += 1;
    return acc;
  }, { development: 15, construction: 0, commissioning: 0, 'o&m': 0, decommissioning: 20, total: 35 });
  phases.construction += phases.commissioning;
  delete phases.commissioning;

  useEffect(() => {
    dispatch(actions.data.setNotificationsCount(0));
  }, [dispatch, play]);

  const animate = (timestamp) => {
    if (startTime.current === undefined) {
      startTime.current = timestamp;
    }
    const elapsed = timestamp - startTime.current;
    if (elapsed === 0) {
      step.current = value[0];
      animationChange(value[0]);
    }
    const stepInterval = 60000 / speedFactorRef.current;

    if (elapsed > stepInterval) {
      if (!pauseRef.current) {
        startTime.current = timestamp;
        step.current += 1;
        if (step.current < (value[1] + 1)) {
          animationChange(step.current);
        }
      }
    }
    if (step.current < (value[1] + 1)) {
      animation.current = requestAnimationFrame(animate);
    } else if (repeatRef.current) {
      startTime.current = timestamp;
      step.current = value[0]
      animationChange(value[0]);
      animation.current = requestAnimationFrame(animate);
    } else {
      setPlay(false);
      startTime.current = undefined;
      step.current = undefined;
      animationChange(undefined);
    }
  }

  const playHandler = () => {
    if (!play) {
      animation.current = requestAnimationFrame(animate);
      setPlay(true);
    }
    setPause(false);
  }

  const stopHandler = () => {
    if (play) {
      cancelAnimationFrame(animation.current);
      startTime.current = undefined;
      animationChange(undefined);
      setPause(false);
      setPlay(false);
    }
  }

  const pauseHandler = () => {
    setPause(!pause);
  }

  const repeatHandler = () => {
    repeatRef.current = !repeat;
    setRepeat(!repeat);
  }

  let multiplierIcon = <X1Icon className={/* repeat ? 'control active' :  */'control'} onClick={() => dispatch(actions.data.setSpeedMultiplier(2))} />;
  if (speedMultiplier === 2) {
    multiplierIcon = <X2Icon className={/* repeat ? 'control active' :  */'control'} onClick={() => dispatch(actions.data.setSpeedMultiplier(3))} />;
  }
  if (speedMultiplier === 3) {
    multiplierIcon = <X3Icon className={/* repeat ? 'control active' :  */'control'} onClick={() => dispatch(actions.data.setSpeedMultiplier(1))} />;
  }

  return (
    <Wrapper visible={visible}>
      <Header>
        <HeaderText>
          {title}
        </HeaderText>
        <HeaderControlls>
          <StopIcon className={/* repeat ? 'control active' :  */'control'} onClick={stopHandler} />
          {pause || !play ? (
            <PlayIcon className={/* play ? 'control active' :  */'control'} onClick={playHandler} />
            ) : null}
            {play && !pause ? (
              <PauseIcon className={/* play ? 'control active' :  */'control'} onClick={pauseHandler} />
            ) : null}
          {multiplierIcon}
          <LoopIcon className={repeat ? 'control active' : 'control'} onClick={repeatHandler} />
        </HeaderControlls>
      </Header>
      <PhaseText>
          {phase}
      </PhaseText>
      <Content>
        <DateLabel data={timepoints} />
        <Phases phases={phases} />
        <BarsRange
          min={0}
          max={timepoints.length - 1}
          timepoints={timepoints}
          play={play}
          count={236}
          current={timelineValue}
          phases={phases}
          value={value}
          onChange={rangeChange}
        />
        <DateLabel showHours data={[timepoints[value[0]], timepoints[value[1]]]}>
          <p>Selected Interval:</p>
        </DateLabel>
      </Content>
    </Wrapper>
  );

}

export default Play;
