import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  background: #1A172B;
  height: 100%;
  padding: 12px 20px 12px 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #fff;
`;

export const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  float: left;

  & p {
    margin: 0;
  }

  & svg:first-child {
    stroke: #fff;
    fill: #fff;
  }
`;

export const Image = styled.img``;

export const Text = styled.p`
  margin-left: 18px !important;
  color: inherit;
  font: inherit;
  font-size: 20px;
`;

export const Mode = styled.p`
  margin: 0;
  transform: translate(-50%, 6px);
  position: absolute;
  left: 50%;
`;

export const InputWrapper = styled.div`
  width: 320px;
  margin-left: 148px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  background: #424252;
  border-radius: 3px;
  position: relative;
  line-height: 17px;
  color: #F0F0F5;
`;

export const Input = styled.input`
  background: transparent;
  border: none;
  width: 290px;
`;

export const SearchItemsList = styled.ul`
  position: absolute;
  top: 0;
  z-index: 1;
  list-style: none;
  top: 20px;
  left: 0;
  width: 100%;
  background-color: #323242;
  padding-inline-start: 0;
`;

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 20px;
  float: right;

  & svg {
    margin-left: 20px;
  }
`;


