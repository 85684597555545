import styled from 'styled-components';


export const Wrapper = styled.div``;

export const Header = styled.header`
  font: inherit;
  line-height: 17px;
  color: #f0f0f5;
  margin-top: 16px;
`;

export const Content = styled.div``;